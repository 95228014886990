@use "../../../../../base/Resources/Public/Scss/includes/core" as core;

// @todo stefi mobile
// auf Startseite sichtbar
// showcase-url: https://go-e.local/de-at/showcase/build/modules/goe/products-coverflow.html

@mixin coverflowLayout2(){
  .products-coverflow {
    @include core.named-breakpoint(medium, down) {
      margin-bottom: var(--space-big);
    }
    &__slider {
      margin-top: 0;
      width: 100%;
      @include core.named-breakpoint(large) {
        width: 33.33%;
      }
    }
    &__button {
      font-size: 1.2rem;
      padding: var(--space-tiny);
      border-radius: var(--space-big);
      &.previous {
        transform: translate(2rem, -8rem);
        @include core.named-breakpoint(large) {
          transform: translate(1.5rem, -4rem);
        }
      }
      &.next {
        transform: translate(-2rem, -8rem);
        @include core.named-breakpoint(large) {
          transform: translate(-1.5rem, -4rem);
        }
      }
    }
    &__product-title, &__product-image {
      flex: 0 1 auto;
    }
    &__product-title {
      order: 2;
      :is(h5, .h5) {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
      }
      .product-label {
        position: relative;
        bottom: 0;
        font-size: var(--font-size-smaller) !important;
        left: 0;
        transform: none;
        display: inline-block;
      }
    }
    &__product-image {
      order: 1;
      height: 13rem;
    }
    &__product-info {
      order: 3;
      opacity: 1;
      visibility: visible;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      > div {
        flex: 1 1 auto;
        margin-bottom:var(--space-regular);
      }
      :is(.base-button, .base-card__link.base-card__stick-to-bottom) {
        margin-top: 0;
        flex: 0 1 auto;
        align-self: center;
        opacity: 0;
        visibility: hidden;
        transition: all 1s ease 0s;
      }
    }
    &__slide {
      justify-content: stretch;

      @include core.named-breakpoint(medium, down) {
        width: calc(100% - (var(--space-big) * 3));
        padding: 0 calc(var(--space-big) * 1.5);
      }

      &.active {
        margin-top: 0;
        .products-coverflow {
          &__product-title {
            h5, .h5{
              transform: none;
            }
          }
          &__product-info {
            :is(.base-button, .base-card__link.base-card__stick-to-bottom) {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.type-module_goe_products-coverflow {
  > .base-container:not(.base-container--no-max-width) {
    .products-coverflow {
      width: calc(var(--products-coverflow-slide-width) * 3);
    }
  }
}

.products-coverflow {
  --products-coverflow-slide-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include core.named-breakpoint(xlarge){
    max-width: 100vW;
  }

  &__button {
    position: relative;
    z-index: 1;
    margin: 0;
    background-color: transparent;
    color: var(--color-link-hover);
    border: 2px solid transparent;
    font-size: 1.8rem;
    padding: var(--space-tiny);
    border-radius: var(--space-big);
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    transition: opacity 0.2s ease 0s, border-color 0.2s ease 0s;
    &:is(:disabled) {
      cursor: none;
      color: transparent;
      opacity: 0;
    }
    &.previous { transform: translate(2.5rem, -4rem); }
    &.next { transform: translate(-2.5rem, -4rem); }
    > .icon.svg { margin-top: -5px; }
  }
  &__slider {
    position: relative;
    width: var(--products-coverflow-slide-width);
    max-width: 90vw;
    margin-top: 6rem;
    margin-bottom: var(--space-tiny);
    display: flex;
    flex-direction: row;
    overflow: visible;
    justify-content: flex-start;
    flex-shrink: 0;
    transition: transform 0.4s ease-in-out;
  }
  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: calc(100% - var(--space-regular) - var(--space-regular));
    padding: 0 var(--space-regular);
    flex-shrink: 0;
    transition: transform 0.3s ease 0s, margin 0.3s ease 0s;

    &.active {
      margin-top: -3rem;
      .products-coverflow {
        &__product-title {
          :is(h5, .h5) {
            transform: scale(1.6);
            @include core.named-breakpoint(medium, down) {
              transition: font-size 0.3s ease 0s;
              transform: none;
              font-size: 1.5rem;
            }
          }
          .product-label {
            font-size: var(--font-size-regular);
          }
        }
        &__product-image {
          > figure {
            width: 100%;
            height: 100%;
          }
        }
        &__product-info {
          visibility: visible;
          opacity: 1;
          transition: all 1s ease 0s;
        }
      }
    }
  }
  &__product-title {
    display: block;
    width: 100%;
    position: relative;
    text-align: center;
    :is(h5, .h5) {
      transition: transform 0.3s ease 0s;
      font-weight: bold;
    }
    .product-label {
      display: block;
      font-size: var(--font-size-smaller);
      text-transform: uppercase;
      color: var(--color-link-hover);
      border: solid 1px var(--color-link-hover);
      border-radius: 0.5rem;
      padding: 0.1rem 0.4rem;
      margin-bottom: 0.4rem;
      position: absolute;
      bottom: 100%;
      white-space: nowrap;
      transition: font-size 0s ease 0s;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__product-image {
    display: block;
    position: relative;
    width: 90%;
    height: 20rem;
    padding: var(--space-tiny) 0;
    > figure {
      transition: width 0.5s ease 0s, height 0.5s ease 0s;
      position: relative;
      width: 75%;
      height: 75%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      > img {
        top: 0;
        left: 0;
        position: absolute;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__product-info {
    opacity: 0;
    visibility: hidden;
    .base-button {
      --color-link: #fff;
      font-weight: bold;
      font-size: var(--font-size-bigger);
      margin-bottom: var(--space-tiny);
    }
    .base-card__link {
      text-align: center;
      font-size: var(--font-size-regular);
      padding: var(--space-tiny);
    }
  }

  &--layout-2 {
    @include coverflowLayout2;
  }
}




.base-content-sidebar-parent-container {
  @include coverflowLayout2;
  .products-coverflow {
    &__product-title {
      :is(h5, .h5) {
        font-size: 1.1rem;
        margin-bottom: var(--space-regular);
      }
    }
    &__product-info {
      > div {
        margin-bottom: var(--space-big);

        @include core.named-breakpoint(large) {
          margin-bottom: 0;
        }
      }
    }
  }
}